.parallax-container {
    position: relative;
    overflow: hidden;
    height: 100vh;
}

.parallax-bg {
    background-image: url('https://www.kindacode.com/wp-content/uploads/2022/05/orange-background.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;
    z-index: -1;
}

.parallax-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    padding: 50px;
    height: 100%;
}