.video-container{
    position: relative;
    max-width: 480px;
}

.video-container-full{
    position: relative;
    max-width: 980px;
}

.video-container>.player-buttons,
.video-container-full>.player-buttons{
    left: 93%;
    position: absolute;
    top: 88%;
    z-index: 1; 
}

.video-container-full>.assesment-buttons-overlay{
    left: 43%;
    position: absolute;
    top: 90%;
    z-index: 1; 
}