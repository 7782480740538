progress.progress-bar {
    width: 100%;
    height: 10px;
    appearance: none;
}

progress.progress-bar::-webkit-progress-bar {
    background-color: #e5e7eb;
    /* Warna latar belakang progress bar */
    border-radius: 5px;
}

progress.progress-bar::-webkit-progress-value {
    background-color: #3b82f6;
    /* Warna biru untuk progress yang berjalan */
    border-radius: 5px;
}

progress.progress-bar::-moz-progress-bar {
    background-color: #3b82f6;
    /* Warna biru untuk progress yang berjalan */
    border-radius: 5px;
}