/* ICON */
.total-test {
  border-radius: 8px;
  background: linear-gradient(225deg, #F7931F 0%, #E8750E 49.48%, #C0590E 100%);
}

.finished-test {
  border-radius: 8px;
  background: linear-gradient(225deg, #0E9F6E 0%, #057A55 48.44%, #046C4E 100%);
}

.profile-percentage {
  border-radius: 8px;
  background: linear-gradient(225deg, #3F83F8 0%, #1C64F2 52.60%, #1A56DB 100%);
}